import anime from 'animejs';

$(window).bind('load', function() {
	if($('.container--body').is('#homepage')) {

		setTimeout(function() {
			$('svg.logomark, svg.loading-logo').addClass('animate');
		},100);
	
		setTimeout(function() {
			$('.logomark').addClass('full');
		},750);
	
		setTimeout(function() {
			$('.container').addClass('show');
		},2800);
	
		$('#loader').delay(2800).fadeOut(200);
	
		anime({
			targets: '.bg-box-left',
			easing: 'easeOutQuint',
			translateY: 40,
			opacity: [0, 1],
			delay: 2800,
			duration: 500
		});
	
		anime({
			targets: '.img-right',
			easing: 'easeOutQuint',
			translateY: 30,
			opacity: [0, 1],
			delay: 2900,
			duration: 500
		});
	
		anime({
			targets: '.bg-box-right',
			easing: 'easeOutQuint',
			translateY: 40,
			opacity: [0, 1],
			delay: 3100,
			duration: 500
		});
		
		anime({
			targets: '.content',
			easing: 'easeOutQuint',
			translateY: 20,
			opacity: [0, 1],
			delay: 3100,
			duration: 500
		});
	
		setTimeout(function() {
			$('.content-cover').css('width','0');
		}, 3100);
	}
	else {
		$('#loader').delay(20).fadeOut(200);
	
		anime({
			targets: '.bg-box-left',
			easing: 'easeOutQuint',
			translateY: 40,
			opacity: [0, 1],
			delay: 20,
			duration: 500
		});
	
		anime({
			targets: '.img-right',
			easing: 'easeOutQuint',
			translateY: 30,
			opacity: [0, 1],
			delay: 120,
			duration: 500
		});
	
		anime({
			targets: '.bg-box-right',
			easing: 'easeOutQuint',
			translateY: 40,
			opacity: [0, 1],
			delay: 320,
			duration: 500
		});
		
		anime({
			targets: '.content',
			easing: 'easeOutQuint',
			translateY: 20,
			opacity: [0, 1],
			delay: 420,
			duration: 500
		});
	
		setTimeout(function() {
			$('.content-cover').css('width','0');
		}, 440);
	}
});