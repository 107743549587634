import gsap from 'gsap';
import inlineSVG from 'inline-svg';
import './components/windowLoadAnime';
import Tilt from './components/Tilt';

$(document).ready(function() {

	// inlineSVG
	inlineSVG.init({
		svgSelector: 'img.svg', // the class attached to all images that should be inlined
		initClass: 'js-inlinesvg', // class added to <html>
	}, function () {
	});


	// Image Mousemove
	var movementStrength = 25;
	var height = movementStrength / $(window).height();
	var width = movementStrength / $(window).width();

	$(window).mousemove(function(e){
		var pageX = e.pageX - ($(window).width() / 2);
		var pageY = e.pageY - ($(window).height() / 2);
		var newvalueX = width * pageX * -1 - 25;
		var newvalueY = height * pageY * -1 - 50;

		$('.img-right').css('background-position', newvalueX+'px '+newvalueY+'px');
	});


	// Title Mousemove
	const titleFill = document.querySelector('.tilt__title')
	const totalTiltText = 3;
	const innerTitleTmp = document.createElement('span');
	innerTitleTmp.className = 'tilt__title-inner';
	innerTitleTmp.innerHTML = titleFill.innerHTML;
	titleFill.innerHTML = '';
	for (let i = 0; i <= totalTiltText-1; ++i) {
		titleFill.appendChild(innerTitleTmp.cloneNode(true));
	}
	const titleTilt = new Tilt(titleFill)
	titleTilt.start();

});


// Navigation

var toggles = document.querySelectorAll(".c-hamburger");

for (var i = toggles.length - 1; i >= 0; i--) {
	var toggle = toggles[i];
  toggleHandler(toggle);
};

function toggleHandler(toggle) {
	toggle.addEventListener("click", function(e) {
		e.preventDefault();
    if (this.classList.contains("is-active") === true) {
			this.classList.remove("is-active");
			$('.open').removeClass('oppenned');
			// $(".show").css('opacity','1');
			// $(".container-menu").removeClass("zoomIn");
			// $(".link").removeClass("animated fadeInLeft");
			// $(".link").attr("style","display: none;");
			
    } else {
			this.classList.add("is-active");
			$(".open").addClass('oppenned');
			$('.container-menu').toggleClass('animate');
			// $(".show").css('opacity','0');
			// $(".container-menu").addClass("zoomIn");
			// $(".link").addClass("animated fadeInLeft");
			// $(".link").attr("style","display: inline-block;");
    }
  });
}

$(".sub-menu li a").click(function(event) {
	// $(".open").removeClass('oppenned');
	// $(".c-hamburger").removeClass('is-active');
	// $(".show").css('opacity','1');

	// $(".link").removeClass("animated fadeInLeft");
	// $(".link").attr("style","display: none;");
});

$(".btn").click(function() {
	$(".open").removeClass('oppenned');

	$(".c-hamburger").removeClass('is-active');
	// $(".show").css('opacity','1');

	// $(".container-menu").removeClass("zoomIn");
	// $(".link").removeClass("animated fadeInLeft");
	// $(".link").attr("style","display: none;");
});

// $(document).ready(function() {
  // var curPos = $(document).getElementById();
  // var drinksPage = $("#drinks");
  
	// $('#drinks').load(function(){
  //   $('.hamburger-wrapper').css('background-color', 'white');
  // });

// });

/* Menu hide & show */
const buttons = document.querySelectorAll('.foodsmenu-button');

buttons.forEach(button => {
	button.addEventListener('click', () => {
		toggleButtons(button);
		toggleFoodsMenu(button);
  });
})

let menuTL
function toggleFoodsMenu(button) {
	const foodsmenu = document.querySelectorAll('.foods-container');
	foodsmenu.forEach(plan => plan.classList.remove('active'));

	const showFoodsMenu = button.getAttribute('data-foodsmenu');
	const foodsMenuToShow = document.querySelector(`.foods-container[data-foodsmenu="${showFoodsMenu}"]`);
	foodsMenuToShow.classList.add('active');

	const titles = document.querySelectorAll('.foods-container.active .menu-item__title')
	const captions = document.querySelectorAll('.foods-container.active .menu-item p')

	if (menuTL) menuTL.kill()
	menuTL = gsap.timeline();
	menuTL.fromTo(titles, { y: 20, autoAlpha: 0 }, { duration: 0.4, y: 0, autoAlpha: 1, stagger: 0.05 })
		  .fromTo(captions, { y: 20, autoAlpha: 0 }, { duration: 0.4, y: 0, autoAlpha: 1, stagger: 0.05 }, "-=0.5")
}

function toggleButtons(button) {
  buttons.forEach(button => button.classList.remove('active'));
  button.classList.add('active');
}


/* Change color social media */

$(window).bind('load', function() {
	if($('.container--body').is('#contact-us')) {
		$('.icon-fb').css('fill' , '#e0d5d1');
		$('.icon-ig').css('fill' , '#e0d5d1');
		$('.icon-fb').addClass('hover-beige');
		$('.icon-ig').addClass('hover-beige');
	}
	else if($('.container--body').is('.theme-beige')) {
		$('.icon-fb').css('fill' , '#e0d5d1');
		$('.icon-ig').css('fill' , '#e0d5d1');
		$('.icon-fb').addClass('hover-red');
		$('.icon-ig').addClass('hover-red');
	}
	else if($('.container--body').is('.theme-red')) {
		$('.icon-fb').css('fill' , '#ad5f5b');
		$('.icon-ig').css('fill' , '#ad5f5b');
		$('.icon-fb').addClass('hover-red');
		$('.icon-ig').addClass('hover-red');
	}
});


/* Add foods link become active class when li is active */

$(".container-foods-link > li").click(function(){
	$(".container-foods-link > li").removeClass('active');
  $(".container-foods-link > li > a").removeClass('active');
  if($(this).hasClass('active')){
    $(this).child().addClass('active');
  }
});

const foodPage = document.getElementById('foods')
if (foodPage) {
	const titles = document.querySelectorAll('.foods-container.active .menu-item__title')
	const captions = document.querySelectorAll('.foods-container.active .menu-item p')
	const menuTL = gsap.timeline();
	gsap.set([titles, captions], { y: 20, autoAlpha:0 })
	
	setTimeout(() => {
		menuTL.to(titles, { duration: 0.4, y: 0, autoAlpha: 1, stagger: 0.05 })
			  .to(captions, { duration: 0.4, y: 0, autoAlpha: 1, stagger: 0.05 }, "-=0.5")
	}, 1000);
}

// $( document ).ready(function() {
// 	$(".container-foods-link > li > a").removeClass('active');
// 	if ($('.container-foods-link > li').is('active')){
//     $('.container-foods-link > li').child().addClass('active');
// 	}
// 	else if ($('.container-foods-link > li').not('active')) {
// 		$('.container-foods-link > li > a').child().removeClass('active');
// 	}
// });

/* Change body css */

$(window).bind('load', function() {
	if($('.container--body').is('#dining')) {
		$('body').css('overflow-y' , 'scroll');
	}
	if($('.container--body').is('#foods')) {
		$('body').css('overflow-y' , 'scroll');
	}
	if($('.container--body').is('#contact-us')) {
		$('body').css('overflow-y' , 'scroll');
	}
});

/* Scroll to events from dining */

$(document).ready(function(){
	$(".next-link").on('click', function(event) {
		
		if (this.hash !== "") {
			event.preventDefault();
			var hash = this.hash;
			
			$('html, body').animate({
				scrollTop: $(hash).offset().top
			}, 500, function() {
				window.location.hash = hash;
			});
		} // End if
	});
});

